import store from "@/store/index.js";
import headerList from "@/components/common/header-list.vue";
import paginationMixin from "@/Mixins/paginationMixin";
import { _ } from "vue-underscore";
import assignModal from "../../manage/lead-assign.vue";
import feedbackModal from "../../manage/lead-feedback.vue";
import phoneCallModal from "../phoneCall-modal.vue";
import SmsModal from "../SMS-modal.vue";
import emailModal from "../email-modal.vue";
import leadCommentFeedbackModal from "../leadFeedbackComments-modal.vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import leadSearch from "../../../sales/list/saleRefSearch.vue";
export default {
  data: () => ({
    lead: {
      lead_id: "",
      lead_number: "",
      rate_request: "",
      isSelected: false,
    },
    showPhoneCall: false,
    selectedClient: {},
    showSmsCall: false,
    showEmails: false,
    lead_feedback_comment: [],
    comments: [],
    newComment: "",
    users: [],
    quotations: [],
    commentList: [],
    showLoader: false,
    searchText: "",
    modalOpen: false,
    selectedLead: null,
    selectedRating: null,
    selectedLeadNumber: "",
    selectedLeadId: [],
    selectedLeadRating: "",
    currentComponent: null,
    newComment: "",
    showButton: false,
    selectedRows: [],
    selectedLeadIds: [],
    teamList: [],
    agentList: [],
    phoneButton: true,
    smsButton: true,
    emailButton: true,
    selectedTeam: "",
    selectedAgent: "",
    leadData: {},
    otherLeadData: {},
    expandQueue: {
      Bonus: true,
      Duplicate: false,
      Wrong_Contact_Info: false,
      Follow_Up_Done: false,
      Client_Not_Ready: false,
      Closed: false,
      Expired: false,
      Sold: false,
      Transferred: false,
    },
    selectedReason: {
      queue: "",
      reason: "",
    },
    all_leads: localStorage.getItem("all_leads") === "true" || false,
    callQueuePresence:
      parseInt(localStorage.getItem("call_queue_presence")) || 0,
    datePickerFormat: "YYYY-MMM-DD",
    leadDateFrom: "",
    leadDateTo: "",
    searchLeadName: "",
    clientLastname: "",
    clientFirstname: "",
    showleadCommentFeedBack: false,
    ClearSearch: false,
    takebonusbutton: false,
    leadSearch: false,
    selectedSearchInput: {
      agent: true,
      lead_id: true,
      client_name: true,
      client_email: true,
      client_phone: true,
      from: true,
      to: true,
    },
    lastQueryString: "",
  }),
  mixins: [paginationMixin],
  components: {
    "header-list": headerList,
    "lead-assign": assignModal,
    "lead-feedback": feedbackModal,
    "phoneCall-modal": phoneCallModal,
    "SMS-modal": SmsModal,
    "email-modal": emailModal,
    "date-picker": DatePicker,
    "lead-CommentFeedBack": leadCommentFeedbackModal,
    "lead-search": leadSearch,
  },
  watch: {
    all_leads(newValue, oldVal) {
      localStorage.setItem("all_leads", newValue);
      this.getAll();
    },
    leadDateFrom(newVal) {
      if (!newVal) {
        this.leadDateTo = "";
      }
    },
  },
  computed: {
    // selectedLeadList() {
    //   let leads = [];
    //   Object.values(this.leadData).forEach((queue) => {
    //     queue.leads.forEach((lead) => {
    //       if (lead.isSelected == true) {
    //         leads.push(lead);
    //       }
    //     });
    //   });
    //   return leads;
    // },
    user() {
      return this.$store.state.userDetail;
    },
    isBonusInfoShowLeadPermission() {
      const permission = this.hasBonusInfoShowLeadPermission();
      return permission;
    },
    isToggleSwitchPermission() {
      const permission = this.hasToggleSwitchPermission();
      return permission;
    },
    isLeadDateToDisabled() {
      return this.leadDateFrom === "" || this.leadDateFrom === null;
    },
  },
  methods: {
    refreshLeadList() {
      let _vm = this;
      _vm.getAll();
    },
    updateQueuePresence() {
      const newValue = this.callQueuePresence === 1 ? 0 : 1;
      this.axios
        .get("/update-queue-presence", {
          params: {
            presence: newValue,
          },
        })
        .then((response) => {
          this.callQueuePresence = newValue;
          localStorage.setItem("call_queue_presence", newValue);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    handleSearch(selectedData) {
      let _vm = this;
      _vm.getAll(selectedData);
      _vm.leadSearch = false;
      _vm.ClearSearch = true;
    },
    formatDateTime(timestamp) {
      const date = new Date(timestamp);
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      const month = months[date.getMonth()];
      const day = date.getDate().toString().padStart(2, "0");
      const year = date.getFullYear().toString().slice(2);
      const time = timestamp.slice(11, 16);

      return `${month}/${day}/${year} ${time}`;
    },
    formatQueueName(key) {
      switch (key) {
        case "Bonus":
          return "Bonus";
        case "Duplicate":
          return "Duplicate";
        case "Wrong_Contact_Info":
          return "Wrong Contact Info";
        case "Follow_Up_Done":
          return "Follow Up Done";
        case "Client_Not_Ready":
          return "Client Not Ready";
        case "Closed":
          return "Closed";
        case "Expired":
          return "Expired";
        case "Sold":
          return "Sold";
        case "Transferred":
          return "Transferred";
      }
    },
    toggleTable(queueIndex) {
      this.expandQueue[queueIndex] = !this.expandQueue[queueIndex];
    },
    hasBonusInfoShowLeadPermission() {
      const slugsList = this.$store.state.slugsList;
      const desiredData = "leads-bonus-info-show";
      let hasPermission = slugsList.includes(desiredData);
      return hasPermission;
    },
    hasToggleSwitchPermission() {
      const slugsList = this.$store.state.slugsList;
      const desiredData = ["other-leads-bonus-view", "other-leads-closed-view"];
      let hasPermission = desiredData.some((item) => slugsList.includes(item));
      return hasPermission;
    },

    // hasCloseOtherLeadPermission() {
    //   const slugsList = this.$store.state.slugsList;
    //   const desiredData = "leads-close-other";
    //   let hasPermission = slugsList.includes(desiredData);
    //   return hasPermission;
    // },
    // hasApproveAndRejectPermission() {
    //   const slugsList = this.$store.state.slugsList;
    //   const desiredData = "leads-close-approve-reject";
    //   let hasPermission = slugsList.includes(desiredData);
    //   return hasPermission;
    // },
    // hasleadsFeedBackPermission() {
    //   const slugsList = this.$store.state.slugsList;
    //   const desiredData = "leads-feedback";
    //   let hasPermission = slugsList.includes(desiredData);
    //   return hasPermission;
    // },

    // hasCreateLeadPermission() {
    //   const slugsList = this.$store.state.slugsList;
    //   const desiredData = "leads-create-lead";
    //   let hasPermission = slugsList.includes(desiredData);
    //   return hasPermission;
    // },
    // hasFeedbackPermission() {
    //   const slugsList = this.$store.state.slugsList;
    //   const desiredData = "leads-feedback";
    //   let hasPermission = slugsList.includes(desiredData);
    //   return hasPermission;
    // },

    // hasCreateActionPermission() {
    //   const slugsList = this.$store.state.slugsList;
    //   const desiredData = "leads-assign";
    //   let hasPermission = slugsList.includes(desiredData);
    //   return hasPermission;
    // },

    // hasUnassignedInfoShowLeadPermission() {
    //   const slugsList = this.$store.state.slugsList;
    //   const desiredData = "leads-unassigned-info-show";
    //   let hasPermission = slugsList.includes(desiredData);
    //   return hasPermission;
    // },

    clearLocalStorageOnLogout() {
      localStorage.removeItem("allLeads");
    },
    getTeamDetail() {
      const _vm = this;
      this.axios
        .get("/team-details", _vm.teamList)
        .then(function (response) {
          _vm.teamList = response.data.data;
        })
        .catch(function () {});
    },
    getTeamWiseAgentDetail() {
      const _vm = this;
      this.axios
        .get("/team-wise-user", _vm.agentList)
        .then(function (response) {
          _vm.agentList = response.data.data;
        })
        .catch(function () {});
    },
    assignUser(id = 0) {
      let ids = [];
      if (id > 0) {
        ids = [id];
      } else {
        Object.values(this.leadData).forEach((queue) => {
          queue.leads.forEach((lead) => {
            if (lead.isSelected == true) {
              ids.push(lead.id);
            }
          });
        });
      }
      this.selectedLeadIds = ids;
      this.currentComponent = "lead-assign";
    },
    updateAssignment() {
      this.currentComponent = null;
      this.getAll(false);
    },
    phoneCall(lead, leadNumber) {
      let _vm = this;
      let clientId = lead.client_id;
      _vm.getDetail(clientId);
      _vm.selectedLeadNumber = leadNumber;
      _vm.selectedLeadId = [lead.id];
      _vm.selectedLead = lead;
      _vm.showPhoneCall = true;
    },
    sms(lead, leadNumber) {
      let _vm = this;
      let clientId = lead.client_id;
      _vm.getDetail(clientId);
      _vm.selectedLeadNumber = leadNumber;
      _vm.selectedLeadId = [lead.id];
      _vm.selectedLead = lead;
      _vm.showSmsCall = true;
    },

    email(lead, leadNumber) {
      let _vm = this;
      let clientId = lead.client_id;
      _vm.getDetail(clientId);
      _vm.selectedLeadNumber = leadNumber;
      _vm.selectedLeadId = [lead.id];
      _vm.selectedLead = lead;
      _vm.showEmails = true;
    },
    leadCommentFeedbackInfo(lead, leadNumber) {
      let _vm = this;
      let clientId = lead.client_id;
      _vm.getDetail(clientId);
      _vm.selectedLeadNumber = leadNumber;
      _vm.selectedLead = lead;
      _vm.showleadCommentFeedBack = true;
    },
    updateFeedback() {
      this.currentComponent = "";
      this.getAll(false);
    },
    getQueryString(selectedData) {
      let queryString = "?all_leads=" + this.all_leads;
      queryString += selectedData.agent_id
        ? "&agent=" + selectedData.agent_id
        : "";
      queryString += selectedData.Lead ? "&lead_id=" + selectedData.Lead : "";
      queryString += selectedData.Client_name
        ? "&client_name=" + selectedData.Client_name
        : "";
      queryString += selectedData.client_email
        ? "&client_email=" + selectedData.client_email
        : "";
      queryString += selectedData.client_phone
        ? "&client_phone=" + selectedData.client_phone
        : "";
      queryString += selectedData.saleDateFrom
        ? "&from=" + selectedData.saleDateFrom
        : "";
      queryString += selectedData.saleDateTo
        ? "&to=" + selectedData.saleDateTo
        : "";
      return queryString;
    },
    getAll(selectedData = {}) {
      let _vm = this;
      store.state.isLoaderShow = true;
      if (Object.keys(selectedData).length === 0 && this.lastQueryString) {
        selectedData = this.lastQueryString;
      } else {
        this.lastQueryString = selectedData;
      }
      let queryString = this.getQueryString(selectedData);
      this.axios
        .get("/other-leads-by-groups" + queryString)
        .then(function (response) {
          store.state.isLoaderShow = false;
          try {
            let data = JSON.parse(atob(response.data.data));
            Object.values(data).forEach((queue) => {
              queue.leads.forEach((lead) => {
                lead["isSelected"] = false;
              });
            });
            _vm.leadData = data;
            console.log(_vm.leadData);
          } catch (error) {}
        })
        .catch(function (error) {});
    },
    setAssign(event, leadId) {
      let _vm = this;
      this.$nextTick(() => {
        this.axios
          .post(
            `/assign-user?lead_id=${leadId}` + "&user_id=" + event.target.value
          )
          .then(function () {
            _vm.getAll();
          })
          .catch(function (error) {});
      });
    },
    getDetail(clientId) {
      let _vm = this;
      this.axios
        .get("/client-by-lead/" + clientId)
        .then(function (response) {
          _vm.selectedClient = JSON.parse(atob(response.data.data));
        })
        .catch(function (error) {
          // Handle error
        });
    },

    handleCheck(e, id) {
      if (e.target.id === "selectAllCheck") {
        Object.values(this.leadData).forEach((queue) => {
          queue.leads.forEach((lead) => {
            lead.isSelected = e.target.checked;
          });
        });
      } else {
        Object.values(this.leadData).forEach((queue) => {
          queue.leads.forEach((lead) => {
            if (lead.id == id) {
              lead.isSelected = e.target.checked;
            }
          });
        });
      }
    },

    unassignLead(status) {
      this.$router.push("leads/" + status);
    },
    openNewTab(queue) {
      window.open("leads/" + queue, "_blank");
    },
    // for popup search
    openModal() {
      this.leadSearch = true;
    },
    isWithinNext14Months(date) {
      const today = new Date(new Date().setHours(0, 0, 0, 0));
      return date > today;
    },
    clearAll() {
      let _vm = this;
      _vm.ClearSearch = false;
      _vm.lastQueryString = "";
      store.state.isLoaderShow = true;
      _vm.getAll();
    },
    takeLead(lead) {
      let _vm = this;
      store.state.isLoaderShow = true;
      _vm.takebonusbutton = true;
      const leadid = lead.id;
      this.axios
        .get("take-bonus-lead/" + leadid)
        .then(function (response) {
          store.state.isLoaderShow = false;
          _vm.takebonusbutton = false;
          _vm.getAll();
        })
        .catch(function (error) {
          // Handle error
        });
    },
  },
  mounted() {
    this.getAll();
    this.getTeamDetail();
    this.getTeamWiseAgentDetail();
    this.callQueuePresence =
      parseInt(localStorage.getItem("call_queue_presence")) || 0;
  },
};
