<template>
  <div id="SMS-modal">
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal modal-wrapper">
          <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content" style="background-color: white;">
              <b-container fluid>
                <b-row class="">
                  <b-col sm="2">
                    <iq-card class="pb-1 mr-1" style="border: 1px solid lightgray;margin: 5px;">
                      <div class="modal-header msger-inputarea1 text-center pb-1"
                        style="font-weight: 600;color: black;text-align: center;font-size: medium;">
                        <div class="">
                          <span>{{ selectedLead.client_name }} ({{ selectedLead.id }})</span>
                        </div>
                      </div>
                      <div class="modal-body bodyhei">
                        <div class="col-md-2 mt-2" style="font-weight: 700;">
                          <template v-for="(sTL, index) in smsTemplateList">
                            <div class="ml-2 mt-1 small" style="width: max-content;font-weight: 500;">
                              <span @click="openBody(index)" style="cursor: pointer;">{{
                                sTL.name }}</span>
                            </div>
                          </template>
                        </div>
                      </div>
                    </iq-card>
                  </b-col>
                  <b-col sm="10">
                    <div style="margin: 5px 5px 5px 0px;">
                      <div class="modal-header p-0">
                        <form class="msger-inputarea">
                          <select class="form-control mr-2 w-25 h-100 " v-model="to">
                            <option disabled>Select Phone Number</option>
                            <option v-for="(contact, index) in contactList" :key="index"
                              :value="contact.prefix + contact.value">
                              {{ contact.prefix }} {{ maskContactValue(contact.value) }}
                              {{
                                contact.contact_type }}
                            </option>
                          </select>
                          <input type="text" v-model="text" @input="checkSendDisabled()" class="msger-input border-none"
                            maxlength="160" placeholder="Enter your message...">
                          <button type="submit" @click="add()" class="msger-send-btn" :disabled="isSendDisabled">
                            Send
                          </button>

                          <button type="button" class="close pt-0" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true" @click="$emit('close')">&times;</span>
                          </button>
                        </form>
                      </div>
                      <div class="modal-body bodyhei">
                        <section class="msger">
                          <main class="msger-chat">
                            <div class="msg-container" ref="messageContainer">
                              <div class="msg" v-for="(smsText, i) in smsList" :key="i"
                                v-bind:class="smsText.direction === 'inbound' ? 'left-msg' : 'right-msg'">
                                <div class="msg-bubble">
                                  <div class="msg-text msg-info-name">
                                    {{ smsText.text }}
                                  </div>
                                  <div class="msg-info">
                                    <div v-if="smsText.direction === 'inbound'">
                                      <div class="d-flex justify-content-between">
                                        <div class="msg-info-time"> {{
                                          selectedLead.first_name }} </div>
                                        <div class="msg-info-time"> {{
                                          smsText.time.slice(0, -3) }} </div>
                                      </div>
                                      <div class="d-flex justify-content-between">
                                        <div class="msg-info-time mr-3">
                                          From<span class="msg-info-time ml-1">{{
                                            maskChatContactValue(smsText.from)
                                          }}</span></div>
                                        <div class="msg-info-time">To<span class="msg-info-time ml-1"> {{
                                          maskChatContactValue(smsText.to)
                                        }}
                                          </span></div>
                                      </div>
                                    </div>
                                    <div class="msg-info-name" v-else>
                                      <div class="d-flex justify-content-between">
                                        <div class="msg-info-time"> {{
                                          smsText.agent_name }} </div>
                                        <div class="msg-info-time"> {{
                                          smsText.time.slice(0, -3) }} </div>
                                      </div>
                                      <div class="d-flex justify-content-between">
                                        <div class="msg-info-time mr-3">
                                          From<span class="msg-info-time ml-1">{{
                                            maskChatContactValue(smsText.from)
                                          }}</span></div>
                                        <div class="msg-info-time">To<span class="msg-info-time ml-1"> {{
                                          maskChatContactValue(smsText.to)
                                        }}
                                          </span></div>
                                      </div>
                                      <!-- {{ smsText.agent_name }} {{ smsText.from }} -->
                                    </div>
                                    <!-- <div class="msg-info-time">{{smsText.time.slice(0, -3)}}</div> -->
                                  </div>

                                </div>
                              </div>
                            </div>
                          </main>
                        </section>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-container>
            </div>
          </div>

        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import vue from 'vue'
import store from "@/store/index.js";
export default {
  name: 'SMS-modal',
  data() {
    return {
      selectedId: 0,
      selectedLeadNumber: '',
      selectedleadid: [],
      text: "",
      to: "",
      client_id: "",
      showLoader: false,
      smsList: [],
      smsTemplateList: [],
      isSendDisabled: true,
      clientLastname: '',
      clientFirstname: ''
    };
  },
  props: {
    selectedLead: Object,
    selectedClient: Object,
  },
  methods: {
    getSmsStaticTemplates() {
      const lead_id = this.selectedLead.id;
      this.axios
        .get(`/sms-static-templates-list/` + lead_id)
        .then((response) => {
          this.smsTemplateList = response.data.data;

        })
        .catch((error) => { });
    },
    openBody(index) {
      const selectedTemplate = this.smsTemplateList[index];
      this.text = selectedTemplate.body;
      this.checkSendDisabled();
    },
    maskContactValue(value) {
      if (value.length < 6) return value;
      const visibleChars = 2;
      const hiddenChars = value.length - visibleChars - 3;
      const maskedValue = value.substring(0, visibleChars) + '*'.repeat(hiddenChars) + value.substring(value.length - 3);
      return maskedValue;
    },
    maskChatContactValue(value) {
      if (value.length < 6) return value;
      const visibleChars = 4;
      const hiddenChars = value.length - visibleChars - 3;
      const maskedValue = value.substring(0, visibleChars) + '*'.repeat(hiddenChars) + value.substring(value.length - 3);
      return maskedValue;
    },
    add() {
      (this.isSendDisabled = true), (store.state.isLoaderShow = false);
      if (!this.text || this.text.trim().length === 0) {
        return;
      }
      this.axios
        .post("/sms-send", { to: this.to, text: this.text })
        .then((response) => {
          store.state.isLoaderShow = false;
          this.text = null;
          this.getSms();
          this.checkSendDisabled();
        })
        .catch(function () { });
    },
    getSms() {
      const client_id = this.selectedClient.id;
      const lead_id = this.selectedLead.id;
      this.axios
        .get(`/lead-sms-chat?clientId=${client_id}&leadId=${lead_id}`)
        .then((response) => {
          let encryptedData = response.data.data;
          let decryptedData = atob(encryptedData);
          let data = JSON.parse(decryptedData);
          this.smsList = data;
          this.scrollToBottom();
        })
        .catch((error) => { });
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const container = this.$refs.messageContainer;
        container.scrollTop = container.scrollHeight;
      });
    },
    checkSendDisabled() {
      if (!this.text || this.text.trim().length === 0) {
        this.isSendDisabled = true;
      } else {
        this.isSendDisabled = false;
      }
    },
  },
  watch: {
    selectedLead: function (newVal) {
      if (newVal && newVal.client_id) {
        this.getSms();
        this.getSmsStaticTemplates();
      }
    },
  },
  computed: {
    contactList() {
      if (
        this.selectedClient &&
        this.selectedClient.client_phones
      ) {
        const phoneList = this.selectedClient.client_phones;
        const preferredContact = phoneList.find(contact => contact.preferred === 1);
        this.to = preferredContact ? preferredContact.prefix + preferredContact.value : "";
        return phoneList;
      }
      return [];

    },
  },
  mounted() {
    this.scrollToBottom();
    this.getSmsStaticTemplates();
    // setInterval(this.getSms, 5000);
    this.timer = setInterval(this.getSms, 5000);
    this.getSms();
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
}
</script>

<style scoped>
#SMS-modal .modal-dialog {
  --bs-modal-width: 90% !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.cardpadding {
  padding-bottom: 1rem;
}

:root {
  --body-bg: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  --msger-bg: #fff;
  --border: 2px solid #ddd;
  --left-msg-bg: #ececec;
  --right-msg-bg: #579ffb;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: var(--body-bg);
  font-family: Helvetica, sans-serif;
}

/* .bodyhei{
  min-height: 31rem;
  max-height: 31rem;
} */

.modal-body {
  max-height: calc(100vh - 125px) !important;
  min-height: calc(100vh - 125px) !important;
}

.modal-body .msger {
  max-height: calc(100vh - 125px) !important;
  min-height: calc(100vh - 125px) !important;
}

select {
  font-size: xx-large;
}

.msger {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  width: 100%;
  /* min-height: 31rem;
    max-height: 31rem;
    height: calc(100% - 50px); */
  border: 1px solid lightgray;
  border-radius: 0 0 5px 5px;
  background: var(--msger-bg);
}

.msger-header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: var(--border);
  background: #eee;
  color: #666;
}

.msger-chat {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
}

.msger-chat::-webkit-scrollbar {
  width: 6px;
}

.msger-chat::-webkit-scrollbar-track {
  background: #ddd;
}

.msger-chat::-webkit-scrollbar-thumb {
  background: #bdbdbd;
}

.msg {
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
}

.msg:last-of-type {
  margin: 0;
}

.msg-img {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  background: #ddd;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
}

.msg-bubble {
  max-width: 450px;
  padding: 10px;
  border-radius: 4px;
  background: var(--left-msg-bg);
}

.msg-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.msg-info-name {
  font-weight: 600;
}

.msg-info-time {
  font-size: 0.70em;
}

.left-msg .msg-bubble {
  /* border-bottom-left-radius: 0; */
  background-color: aliceblue;
}

.right-msg {
  flex-direction: row-reverse;
}



@media (min-width: 576px) {
  .modal-body .msger {
    max-height: calc(100vh - 55px) !important;
    min-height: calc(100vh - 55px) !important;
    overflow-x: hidden;
  }



  .modal-dialog {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

.modal-dialog {
  margin: var(--bs-modal-margin) !important;
}

.right-msg .msg-bubble {
  background-color: antiquewhite;
  /* border-bottom-right-radius: 0; */
}

.right-msg .msg-img {
  margin: 0 0 0 10px;
}

.msger-inputarea {
  display: flex;
  padding: 10px;
  border-top: var(--border);
  background: #eee;
  width: 100%;
}

.msger-inputarea {
  display: flex;
  padding: 10px;
  border-top: var(--border);
  background: #ccc1f0;
  border-radius: 4px 4px 0 0;
}

.msger-inputarea * {
  padding: 10px;
  border: none;
  border-radius: 3px;
  font-size: 1em;
}

.msger-input {
  /* flex: 1; */
  min-width: 70%;
  max-width: 80%;
}

button.msger-send-btn[disabled] {
  background-color: #ccc;
  color: #888;
  cursor: not-allowed;
}

.msger-send-btn {
  margin-left: 10px;
  width: 10%;
  border: 1px solid;
  background: rgb(0, 196, 65);
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.23s;
}

.msger-send-btn:hover {
  background: rgb(0, 180, 50);
}

.msger-inputarea1 {
  display: flex;
  padding: 10px;
  border-top: var(--border);
  background: #ccc1f0;
  border-radius: 8px 8px 0 0;
}

.msger-inputarea1 * {
  padding: 10px;
  border: none;
  font-size: 1em;
  width: -webkit-fill-available;
}
</style>
